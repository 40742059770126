import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
    BronsonSpinner,
    BronsonToastNotification,
    BronsonToastProps,
} from '@dh/bronson-react';
import { getUserEmail, getUserRole } from '@dh/keycloak-auth';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../context/app.context';
import { Hero } from '../../components/Hero';
import heroImage from '../../assets/img/hero/register-dealer.jpg';
import { insuranceRoles } from '../../config';
import { registerLabels } from './register.labels';
import { RegisterService } from './register.service';
import { RegistrationForm } from '../../components/Register';
import { zero } from '../../utils/constants';

export const Register: React.FC = () => {
    const [keycloak] = useKeycloak();
    const history = useHistory();
    const user: {
        email: string;
        given_name: string;
        family_name: string;
        name: string;
    } = { email: '', given_name: '', family_name: '', name: '' };
    Object.assign(user, keycloak.tokenParsed);
    const [loading, setLoading] = useState<boolean>(true);
    const [registering, setRegistering] = useState<boolean>(false);
    const [toastNotificationConfig, setToastNotificationConfig] =
        useState<BronsonToastProps>({
            isVisible: false,
            content: '',
            type: 'default',
        });
    const { userInfo } = useContext(AppContext);

    const role = getUserRole(keycloak, insuranceRoles);
    const registerService = new RegisterService(keycloak);

    if (keycloak?.authenticated && role) {
        history.push('/');
    }

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length !== zero) {
            //setType(userInfo['type']);
            setLoading(false);
        }
    }, [userInfo]);

    /**
     * Get dealers filtered by brand for Seller form
     */
    const setRole = () => {
        const id = keycloak?.tokenParsed?.sub || '';
        setRegistering(true);
        registerService
            .setRole(id)
            .then(() => window.location.reload())
            .catch(() => {
                setToastNotificationConfig({
                    isVisible: true,
                    content: registerLabels.errorRegisterUser,
                    type: 'error',
                    isClosable: true,
                    setVisibility: () =>
                        setToastNotificationConfig((notification) => ({
                            ...notification,
                            isVisible: false,
                        })),
                });
            });
    };

    const sendNotification = () => {
        const { name, email } = user;
        registerService.sendNotification({ user: name, email });
    };

    return (
        <div>
            <BronsonToastNotification {...toastNotificationConfig} />
            <Hero
                title={registerLabels.heroTitle}
                description={registerLabels.heroDescription}
                iconClass={registerLabels.heroIconClass}
                heroImage={heroImage}
            />
            <main className='o-main'>
                <section>
                    {loading ? (
                        <BronsonSpinner
                            config={{
                                spinnerWrapperClassModifier:
                                    'c-spinner--center u-m-large',
                                showSpinner: true,
                            }}
                        />
                    ) : (
                        <RegistrationForm
                            userEmail={getUserEmail(keycloak)}
                            type={userInfo['type']}
                            registering={registering}
                            actions={{
                                logout: () => keycloak.logout(),
                                setRole,
                                sendNotification,
                            }}
                        />
                    )}
                </section>
            </main>
        </div>
    );
};
