import Keycloak from 'keycloak-js';
import { HttpMethod, Response } from '@dh/keycloak-auth';

import { AppService } from '../../App.service';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class RegisterService extends AppService {
    public nameService: string;

    constructor(keycloak: Keycloak.KeycloakInstance) {
        super(keycloak);
        this.nameService = 'RegisterService';
    }

    /**
     * This method set seller rol to user
     */
    public async setRole(id: string): Promise<Response> {
        return this.client.fetch({
            path: `/assign-role/${id}`,
            method: HttpMethod.POST,
        });
    }

    /**
     * This to send notification id user refuses the seller role
     */
    public async sendNotification(data: {
        email: string;
        user: string;
    }): Promise<Response> {
        return this.client.fetch({
            path: '/send-notification',
            method: HttpMethod.POST,
            body: { ...data },
        });
    }
}
