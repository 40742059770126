import { getUserRole } from '@dh/keycloak-auth';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect, Route } from 'react-router-dom';

import { insuranceRoles } from '../config';
import { RouteDefinition } from './routes.type';

//import { AppService } from '../App.service';

export const PrivateRoute = ({
    component: Component,
    allowedRoles: roles,
    ...rest
}: RouteDefinition) => {
    const [keycloak] = useKeycloak();
    const rol = getUserRole(keycloak, insuranceRoles) || '';

    return (
        <Route
            {...rest}
            render={(props) => {
                if (keycloak.authenticated) {
                    if (rol && roles) {
                        if (roles.includes(rol)) {
                            return <Component {...props} />;
                        } else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/forbidden',
                                        state: { from: props.location },
                                    }}
                                />
                            );
                        }
                    } else return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};
