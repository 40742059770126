import { KeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { KeycloakImplementation, KeycloakParams } from '@dh/keycloak-auth';

import { AppRouter } from './routes';
import { keycloakConfig } from './config';

class App extends React.PureComponent {
    private keycloakParams: KeycloakParams = {
        keycloakConfig,
    };
    // keycloak implementation of the keycloak-js
    public keycloak = new KeycloakImplementation(this.keycloakParams);

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
        };
    }

    public render() {
        return (
            <KeycloakProvider
                keycloak={this.keycloak.instance}
                initConfig={this.keycloak.keycloakProviderInitConfig}
                onEvent={this.keycloak.onKeycloakEvent}
                onTokens={() => {
                    this.keycloak.onKeycloakTokens().then(() => {
                        this.setState({ isReady: true });
                    });
                }}
            >
                <AppRouter />
            </KeycloakProvider>
        );
    }
}

export default App;
